import React, { useMemo } from 'react';
import { STORAGE_TYPES } from '@cld-apis/utils';

import Testimonials from 'components/Globals/Testimonials';
import CustomerLogos from 'components/Globals/CustomerLogos';
import Image from 'components/uiLibrary/Image';

import { TP } from 'constants/index';

import { useTranslation } from 'src/i18n';

import classes from './CastingTool.module.scss';

const BANNERS = [
  'https://public.operabase.com/images/artists/casting_tool/ct_1_20240320T124807038Z.webp', // BALLET
  'https://public.operabase.com/images/artists/casting_tool/ct_2_20240320T124807038Z.webp', // MUSICALS
  'https://public.operabase.com/images/artists/casting_tool/ct_3_20240320T124807038Z.webp', // OPERA
  'https://public.operabase.com/images/artists/casting_tool/ct_4_20240320T124807038Z.webp', // ORCHESTRA
];

const CastingTool = () => {
  const { t } = useTranslation('NS_APP_GLOBALS');

  const { bannerSrc, transformations } = useMemo(() => {
    const title = t(`${TP}.FN_AD_CASTING_TOOL_TITLE`);
    const subtitle = t(`${TP}.FN_AD_CASTING_TOOL_SUBTITLE`);

    const renderIdx = Math.floor(Math.random() * BANNERS.length);

    return {
      bannerSrc: BANNERS[renderIdx],
      transformations: {
        storageType: STORAGE_TYPES.FETCH,
        chaining: [
          {
            overlay: `text:BebasNeue.ttf_128_bold:${encodeURIComponent(title)}`,
            x: 40,
            y: 40,
            gravity: 'north_west',
          },
          {
            crop: 'fit',
            width: 300,
            overlay: `text:Roboto_22_bold:${encodeURIComponent(subtitle)}`,
            x: 40,
            y: 150,
            gravity: 'north_west',
            color: 'white',
          },
        ],
      },
    };
  }, [t]);

  return (
    <div className={classes.root}>
      <div className={classes.banner}>
        <Image src={bannerSrc} transformations={transformations} useIntersectionObserver disableNextImage />
      </div>
      <div className={classes.testimonials}>
        <Testimonials limit={4} />
      </div>
      <div className={classes.logos}>
        <CustomerLogos showTitle={false} allowShowAll={false} rows={3} noFollow />
      </div>
    </div>
  );
};

export default CastingTool;
